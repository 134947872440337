import React from 'react';

import Heading from 'src/components/content/heading/Heading';
import Education from 'src/containers/resume/sections/education/Education';
import Tools from 'src/containers/resume/sections/tools/Tools';
import Work from 'src/containers/resume/sections/work/Work';

import styles from 'src/containers/resume/main/ResumeMain.module.scss';

const ResumeMain = () => (
  <div className={styles.wrapper}>
    <div className={styles.wrapper__inner}>
      <section className={styles.section}>
        <div className={styles.section__header}>
          <Heading priority={1} content="Education" size={2} theme="lightest" />
        </div>
        <Education
          containerClass={`${styles.section__content} ${styles.section__content_half}`}
        />
      </section>
      <section className={styles.section}>
        <div className={styles.section__header}>
          <Heading priority={1} content="Tools" size={2} theme="lightest" />
        </div>
        <Tools
          containerClass={`${styles.section__content} ${styles.section__content_half}`}
        />
      </section>
      <section className={styles.section}>
        <div className={styles.section__header}>
          <Heading priority={1} content="Work" size={2} theme="lightest" />
        </div>
        <Work containerClass={styles.section__content} />
      </section>
    </div>
  </div>
);

export default ResumeMain;
