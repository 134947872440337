import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import styles from 'src/components/content/paragraph/Paragraph.module.scss';

const Paragraph = ({ content, size, theme, variant, weight, wrap }) => {
  const classes = classNames(
    styles.paragraph,
    styles[`paragraph_s${size}`],
    styles[`paragraph_${theme}`],
    styles[`paragraph_${weight}`],
    {
      [styles[`heading_${variant}`]]: variant,
    }
  );

  if (wrap) {
    return <p className={classes}>{content}</p>;
  }

  return <div className={classes}>{parse(content)}</div>;
};

Paragraph.propTypes = {
  content: PropTypes.string.isRequired,
  size: PropTypes.number,
  theme: PropTypes.oneOf(['darkest', 'dark', 'light', 'lightest']),
  variant: PropTypes.oneOf(['subheading']),
  weight: PropTypes.oneOf(['bold', 'medium', 'regular', 'thin']),
  wrap: PropTypes.bool,
};

Paragraph.defaultProps = {
  size: 3,
  theme: 'light',
  variant: null,
  weight: 'regular',
  wrap: false,
};

export default Paragraph;
