import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { useAppContext } from 'src/context/AppContext';
import Dates from 'src/components/content/dates/Dates';
import Heading from 'src/components/content/heading/Heading';
import Paragraph from 'src/components/content/paragraph/Paragraph';

import styles from 'src/containers/resume/sections/work/Work.module.scss';

export const PureWork = ({ data }) => {
  const appContext = useAppContext();

  return (
    <>
      <header className={styles.header}>
        <div
          className={classNames({
            [styles.header__full]: appContext.breakpoint === 'sm',
          })}
        >
          <Heading
            content={data.title}
            size={4}
            priority={3}
            theme="dark"
            variant="title"
            wrap
          />
        </div>
        <div
          className={classNames({
            [styles.header__full]: appContext.breakpoint === 'sm',
          })}
        >
          <Dates startDate={data.startDate} endDate={data.endDate} />
        </div>
        <div className={styles.header__full}>
          <Heading
            content={data.company}
            size={6}
            priority={3}
            theme="dark"
            variant="resume"
            wrap
            weight="thin"
          />
        </div>
      </header>
      <Paragraph content={data.description} size={3} theme="dark" />
    </>
  );
};

PureWork.propTypes = {
  data: PropTypes.shape({
    company: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const Work = ({ containerClass }) => {
  const data = useStaticQuery(graphql`
    query ResumeWork {
      wpPage(slug: { eq: "resume" }) {
        fieldsResumeWork {
          companies {
            company
            description
            endDate
            startDate
            title
          }
        }
      }
    }
  `);

  return (
    <>
      {data.wpPage.fieldsResumeWork.companies.map((company) => (
        <div className={containerClass} key={uuidv4()}>
          <PureWork data={company} />
        </div>
      ))}
    </>
  );
};

Work.propTypes = {
  containerClass: PropTypes.string.isRequired,
};

export default Work;
