import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

const Image = ({ altText, imageData }) => {
  const imageDataRendered = getImage(imageData);
  return <GatsbyImage image={imageDataRendered} alt={altText} />;
};

Image.propTypes = {
  altText: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  imageData: PropTypes.object.isRequired,
};

export default Image;
