import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import Heading from 'src/components/content/heading/Heading';
import List from 'src/components/content/list/List';

import styles from 'src/containers/resume/sections/tools/Tools.module.scss';

export const PureTools = ({ data }) => (
  <>
    <div className={styles.heading}>
      <Heading
        content={data.label}
        size={6}
        priority={3}
        theme="dark"
        variant="resume"
        wrap
        weight="regular"
      />
    </div>
    <div className={styles.list}>
      <List
        content={data.technologies.map((item) => Object.values(item))}
        size={2}
        split
        theme="dark"
        weight="thin"
      />
    </div>
  </>
);

PureTools.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    technologies: PropTypes.arrayOf(
      PropTypes.shape({
        technology: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
};

const Tools = ({ containerClass }) => {
  const data = useStaticQuery(graphql`
    query ResumeToolsQuery {
      wpPage(slug: { eq: "resume" }) {
        fieldsResumeTools {
          list {
            label
            technologies {
              technology
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={containerClass}>
        {data.wpPage.fieldsResumeTools.list.slice(0, 3).map((list) => (
          <PureTools data={list} key={uuidv4()} />
        ))}
      </div>
      <div className={containerClass}>
        {data.wpPage.fieldsResumeTools.list.slice(3).map((list) => (
          <PureTools data={list} key={uuidv4()} />
        ))}
      </div>
    </>
  );
};

Tools.propTypes = {
  containerClass: PropTypes.string.isRequired,
};

export default Tools;
