import React from 'react';

import AppLayout from 'src/layouts/app/AppLayout';
import ResumeMain from 'src/containers/resume/main/ResumeMain';
import ResumeSide from 'src/containers/resume/side/ResumeSide';

import styles from 'src/scss/pages/Resume.module.scss';

const Resume = () => (
  <AppLayout theme="dark">
    <div className={styles.page}>
      <main className={styles.page__main}>
        <ResumeMain />
      </main>
      <aside className={styles.page__side}>
        <ResumeSide />
      </aside>
    </div>
  </AppLayout>
);

export default Resume;
