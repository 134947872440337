import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Heading from 'src/components/content/heading/Heading';
import Image from 'src/components/image/Image';
import Paragraph from 'src/components/content/paragraph/Paragraph';

import styles from 'src/containers/resume/side/ResumeSide.module.scss';

export const PureResumeSide = ({ data }) => (
  <div className={styles.side}>
    <div className={styles.side__headshot}>
      <Image
        imageData={data.headshot.localFile}
        altText={data.headshot.altText}
      />
    </div>
    <div className={styles.side__name}>
      <Heading content={data.name} priority={1} size={1} theme="lightest" />
    </div>
    <div className={styles.side__contact}>
      <Heading
        content={data.contact}
        priority={2}
        size={6}
        variant="subheading"
      />
    </div>
    <div className={styles.side__about}>
      <Paragraph content={data.about} size={2} theme="lightest" weight="thin" />
    </div>
  </div>
);

PureResumeSide.propTypes = {
  data: PropTypes.shape({
    about: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    headshot: PropTypes.object.isRequired,
  }).isRequired,
};

const ResumeSide = () => {
  const data = useStaticQuery(graphql`
    query ResumeSideQuery {
      wpPage(slug: { eq: "resume" }) {
        fieldsResumeSide {
          about
          contact
          name
          headshot {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 225)
              }
            }
          }
        }
      }
    }
  `);

  return <PureResumeSide data={data.wpPage.fieldsResumeSide} />;
};

export default ResumeSide;
