import React from 'react';
import { parse, differenceInCalendarMonths } from 'date-fns';
import PropTypes from 'prop-types';

import Heading from 'src/components/content/heading/Heading';

export const pluralizeLabel = (total, singularLabel) => {
  if (total > 1) {
    return `${singularLabel}s`;
  }
  return singularLabel;
};

const Dates = ({ startDate, endDate }) => {
  const start = parse(startDate, 'MM/yy', new Date());
  const end =
    endDate === 'Present' ? new Date() : parse(endDate, 'MM/yy', new Date());
  const totalMonths = differenceInCalendarMonths(end, start);
  const years = Math.floor(totalMonths / 12);
  const months = totalMonths % 12;

  const getTimeRange = () => {
    if (months > 0) {
      return `(${years} ${pluralizeLabel(
        years,
        'year'
      )}, ${months} ${pluralizeLabel(months, 'month')})`;
    }

    return `(${years} ${pluralizeLabel(years, 'year')})`;
  };

  return (
    <h3>
      <Heading
        content={`${startDate} – ${endDate} `}
        element="span"
        size={6}
        theme="dark"
        variant="resume"
        weight="regular"
      />
      <Heading
        content={getTimeRange()}
        element="span"
        size={7}
        theme="dark"
        variant="resume"
        weight="thin"
      />
    </h3>
  );
};

Dates.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default Dates;
