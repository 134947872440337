import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import Heading from 'src/components/content/heading/Heading';

export const PureEducation = ({ data }) => (
  <>
    <Heading
      content={data.degree}
      size={5}
      priority={3}
      theme="dark"
      variant="resume"
      wrap
      weight="regular"
    />
    <Heading
      content={data.specialization}
      size={6}
      priority={4}
      theme="dark"
      variant="resume"
      wrap
      weight="thin"
    />
    <Heading
      content={data.college}
      size={6}
      priority={4}
      theme="dark"
      variant="title"
      wrap
      weight="thin"
    />
    <Heading
      content={data.year}
      size={6}
      priority={4}
      theme="dark"
      variant="title"
      wrap
      weight="thin"
    />
  </>
);

PureEducation.propTypes = {
  data: PropTypes.shape({
    degree: PropTypes.string.isRequired,
    specialization: PropTypes.string.isRequired,
    college: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }).isRequired,
};

const Education = ({ containerClass }) => {
  const data = useStaticQuery(graphql`
    query ResumeEducationQuery {
      wpPage(slug: { eq: "resume" }) {
        fieldsResumeEducation {
          degrees {
            college
            degree
            specialization
            year
          }
        }
      }
    }
  `);

  return (
    <>
      {data.wpPage.fieldsResumeEducation.degrees.map((degree) => (
        <div className={containerClass} key={uuidv4()}>
          <PureEducation data={degree} />
        </div>
      ))}
    </>
  );
};

Education.propTypes = {
  containerClass: PropTypes.string.isRequired,
};

export default Education;
