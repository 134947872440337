import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from 'src/components/content/list/List.module.scss';

const List = ({ content, size, split, theme, variant, weight }) => {
  const itemClasses = classNames(
    styles[`list__item_s${size}`],
    styles[`list__item_${theme}`],
    styles[`list__item_${weight}`],
    {
      [styles[`list__item_${variant}`]]: variant,
      [styles.list__item_split]: split,
    }
  );

  return (
    <ul
      className={classNames(styles.list, {
        [styles.list_split]: split,
      })}
    >
      {content.map((item) => (
        <li className={itemClasses} key={uuidv4()}>
          {item}
        </li>
      ))}
    </ul>
  );
};

List.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.array.isRequired,
  size: PropTypes.number,
  split: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light']),
  variant: PropTypes.oneOf(['resume', 'subheading']),
  weight: PropTypes.oneOf(['bold', 'medium', 'regular', 'thin']),
};

List.defaultProps = {
  size: 3,
  split: false,
  theme: 'light',
  variant: null,
  weight: 'regular',
};

export default List;
